// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import store from './store/index'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


// import base components
import mainNavigation from './components/Navbars/mainNavigation.vue'
// import bannerSection from './components/Navbars/homeCarousel.vue'
import bannerSection from './components/Body/HomePage.vue'


import FeaturesSection from './components/Body/FeaturesSection.vue'
import PaymentServicesSection from './components/Body/PaymentServicesSection.vue'



const app = createApp(App)

app.use(store)
app.use(router)
// app.component(mainNavigation, 'main-navigation')
app.component('main-navigation', mainNavigation)
app.component('banner-section', bannerSection)
app.component('features-section', FeaturesSection)
app.component('payment-section', PaymentServicesSection)


app.mount('#app')
