<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">New Trade</h6>
<!--        <button-->
<!--          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"-->
<!--          type="button"-->
<!--        >-->
<!--          Settings-->
<!--        </button>-->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="createTrade()">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Trade Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Base Currency
              </label>
<!--              <select v-model="form.base_currency" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" required>-->
<!--                <option disabled value=""> Select</option>-->
<!--                <option>USDT</option>-->
<!--                <option>USD</option>-->
<!--              </select>-->

              <select v-model="form.base_currency" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option disabled value=""> Select one</option>
                <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Counter Currency
              </label>
              <select v-model="form.counter_currency" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option disabled value=""> Select one</option>
                <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
<!--                <option>KES</option>-->
<!--                <option>TZS</option>-->
<!--                <option>ETB</option>-->
<!--                <option>AOA</option>-->
<!--                <option>ZMW</option>-->
<!--                <option>EGP</option>-->
<!--                <option>RWF</option>-->
<!--                <option>MWK</option>-->
              </select>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Select Client
              </label>
              <select v-model="form.client" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option disabled value="">Select client</option>
                <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Select Liquidity Provider
              </label>
              <select v-model="form.liquidity_provider" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option disabled value="">Select client</option>
                <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Volume in Base Currency(USDT)
              </label>
              <input
                type="number" v-model="form.base_volume"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Client Rate
              </label>
              <input
                type="number" v-model="form.c_rate"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Liquidity Rate
              </label>
              <input
                type="number" v-model="form.l_rate"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Volume in Local Currency
              </label>
              <input
                type="number" v-model="form.local_volume"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Notes
              </label>

              <textarea
                type="text" v-model="form.notes"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="4">

              </textarea>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit">Submit</button>
            </div>
          </div>
        </div>


      </form>
    </div>
  </div>

    </div>
<!--    <div class="w-full lg:w-4/12 px-4">-->
<!--      <CardProfile />-->
<!--    </div>-->
  </div>
</template>
<script>
// import CardSettings from "@/components/Cards/CardSettings.vue";
// import CardProfile from "@/components/Cards/CardProfile.vue";

import core_function from "@/services/core_function";

export default {
  data(){
    return{
      form: {},
      clients: [],
      currencies: [],
      newTrade: {},
    }
  },
  components: {
    // CardSettings,
    // CardProfile,
  },
  methods: {
    async getClients(){
      try{
        let response = await core_function.loadClients()
        this.clients = response.data
      }catch (err){
        console.log(err)
      }
    },
    async getCurrencies(){
      try{
        let response = await core_function.loadCurrencies()
        this.currencies = response.data
      }catch (err){
        console.log(err)
      }
    },
    async createTrade(){
      try{
        let response = await core_function.postTrade(this.form)
        if(response.status === 201){
          this.$router.push('/admin/trades')
        }else{
          console.log(response)
        }
      }catch (err){
        console.log(err)
      }
    }
  },
  mounted() {
    this.getClients()
    this.getCurrencies()
  },
  computed: {
    computedBaseVolume() {
      if (this.form.base_volume === null && this.form.local_volume !== null && this.form.c_rate !== null) {
        return (this.form.local_volume / this.form.c_rate).toFixed(2);
      }
      return (this.form.base_volume);
    },

    computedLocalVolume() {
      if (this.form.local_volume === null && this.form.base_volume !== null && this.form.l_rate !== null) {
        return (this.form.base_volume * this.form.l_rate).toFixed(2);
      }
      return (this.form.local_volume);
    },

    computedUsdIn(){
      return (this.computedLocalVolume/this.form.l_rate).toFixed(2);
    },

    computedUsdOut(){
      return (this.computedLocalVolume/this.form.c_rate).toFixed(2);
    },

    computedProfit(){
      return (this.computedUsdIn - this.computedUsdOut).toFixed(2);
    }
  },
};
</script>
