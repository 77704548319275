<template>
  <div>
  <main-navigation></main-navigation>

  <banner-section></banner-section>
  
  
  <!-- <section class="hero">
    <div class="container">
      <div class="hero-content">
        <h1 class="hero-title">A New Way to Manage Payments</h1>
        <p class="hero-subtitle">Seamless payment solutions for a better tomorrow.</p>
        <button class="btn btn-primary">Get Started</button>
      </div>
    </div>
  </section> -->

  <features-section></features-section>

  <payment-section></payment-section>

</div>
  
</template>

<style scoped>
.hero {
  height: 100vh;
  /* background: url('../../public/assets/images/board.jpg') no-repeat center center/cover; */
  position: relative;
  display: flex;
  align-items: center;
  color: rgb(21, 166, 195);
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(158, 168, 241, 0.187); /* Adjust the opacity (0.5) to control the fade level */
  z-index: 1;
}

.hero-content {
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 2; /* Ensure the text is above the overlay */
}

</style>
