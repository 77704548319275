<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full mb-12 px-4">

            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
                 :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3
                      class="font-semibold text-lg"
                      :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
                    >
                      Client List
                    </h3>

                    <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" @click="this.$router.push('/admin/new/client')">New Client</button>
                  </div>
                </div>
              </div>

              <div class="block w-full overflow-x-auto">
                  <!-- Projects table -->
                  <table class="items-start w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th class="px-6 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Client Name</th>
                        <th class="px-6 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Client Type</th>
                        <th class="px-6 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Country</th>
                        <th class="px-6 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Registration Date</th>
                        <th class="px-6 border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Processed</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="client in clients" v-bind:key="client.id">
                        <th class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ client.name }}</th>
                        <th class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ client.client_type }}</th>
                        <th class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ client.country }}</th>
                        <th class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ client.registration_date }}</th>
                        <th class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ client.processed }}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

          </div>
          <div class="w-full mb-12 px-4">
<!--            <card-table color="dark" />-->
          </div>
        </div>
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import core_function from "@/services/core_function";

export default {
  name: "admin-layout",
  data(){
    return {
      clients: []
    }
  },
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    async getClients(){
      try{
        let response = await core_function.loadClients()
        this.clients = response.data
      }catch (err){
        console.log(err)
      }
    }
  },
  mounted() {
    this.getClients()
  }
};
</script>
