import api from '../api/api'


const core_function = {
    loadClients() {
        return new Promise((resolve, reject) => {
          api.get('/clients/', (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    postClient(content) {
        return new Promise((resolve, reject) => {
          api.post('/clients/', content, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadTrades(){
        return new Promise((resolve, reject) => {
          api.get('/trades/', (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    postTrade(content) {
        return new Promise((resolve, reject) => {
          api.post('/trades/', content, (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    loadCurrencies(){
        return new Promise((resolve, reject) => {
          api.get('/currencies/', (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    },
    dashboardData(){
        return new Promise((resolve, reject) => {
          api.get('/dashboard/data/', (data) => {
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
    }
}

export default core_function;