<template>
    <nav class="fixed top-0 left-0 w-full bg-white shadow-md z-10 h-16 flex items-center justify-between px-4">
      <div class="flex items-center">
        <img src="/assets/images/logo.png" alt="Logo" class="h-10"> <!-- Adjust logo height if needed -->
        <span class="text-xl font-bold ml-2">DurraFx</span>
      </div>
      <ul class="flex space-x-4">
        <li><a href="#" class="hover:text-yellow-500">Home</a></li>
        <li><a href="/about" class="hover:text-yellow-500">About</a></li>
        <li><a href="/services" class="hover:text-yellow-500">Services</a></li>
        <li><a href="#contact" class="hover:text-yellow-500">Contact</a></li>
        <li>
          <a
            href="/auth/login"
            class="bg-yellow-500 text-black font-semibold px-4 py-2 rounded-full hover:bg-yellow-600"
          >
            Get Started
          </a>
        </li>
      </ul>
    </nav>
  </template>
  
  <style scoped>
  /* Custom styles if needed */
  nav {
    height: 5.5rem; /* Adjust height as needed */
    background: #f9f6f7;
  }
  </style>
  