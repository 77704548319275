<template>
    <div class="payment-services-section">
      <div class="header">
        <h2>All Your Payments In One Place</h2>
        <p>Get used to low fees and great exchange rates on international money transfers. Expand your business worldwide.</p>
      </div>
      <div class="services-list">
        <div class="service-card">
          <div class="icon">💳</div>
          <h3>Payments</h3>
          <p>Make global payments to any account using our platform</p>
        </div>
        <div class="service-card">
          <div class="icon">📦</div>
          <h3>Collections</h3>
          <p>We help companies do collections in foreign countries and have the money in one wallet</p>
        </div>
        <div class="service-card">
          <div class="icon">🔄</div>
          <h3>Conversions</h3>
          <p>We convert currencies to enable you get what you want to use at that particular tile at the best rates.</p>
        </div>
        <div class="service-card">
          <div class="icon">🌐</div>
          <h3>Global Account</h3>
          <p>Get one wallet for all your currencies and transact globally.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PaymentServicesSection',
  };
  </script>
  
  <style scoped>
  .payment-services-section {
    padding: 4rem 2rem; /* Add padding for spacing */
    background: linear-gradient(135deg, #1c1c1e, #3a3a3c); /* Gradient background */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
  }
  
  .header {
    margin-bottom: 3rem; /* Space between header and services */
  }
  
  h2 {
    font-size: 2.5rem; /* Larger font size for the title */
    font-weight: bold; /* Bold text */
  }
  
  p {
    font-size: 1.2rem; /* Font size for description */
    margin-top: 0.5rem; /* Space above description */
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid layout */
    gap: 2rem; /* Space between cards */
  }
  
  .service-card {
    background: white; /* White background for cards */
    color: #000; /* Black text color for readability */
    border-radius: 10px; /* Rounded corners */
    padding: 2rem; /* Padding inside cards */
    transition: transform 0.3s; /* Smooth transform effect */
  }
  
  .service-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
  }
  
  .icon {
    font-size: 3rem; /* Size for icons */
    margin-bottom: 1rem; /* Space below icon */
  }
  </style>
  