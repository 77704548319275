<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">New Trade</h6>
<!--        <button-->
<!--          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"-->
<!--          type="button"-->
<!--        >-->
<!--          Settings-->
<!--        </button>-->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="postClient()">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Trade Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Business Identification Number
              </label>
              <input type="text"
                     v-model="form.business_identification_number"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Business Name
              </label>
              <input type="text"
                     v-model="form.name"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Country
              </label>
              <input type="text"
                     v-model="form.country"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Status
              </label>
              <select v-model="form.status" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option disabled value="">Please select status</option>
                <option>Active</option>
                <option>Pending KCV</option>
                <option>Deactivated</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Client Type
              </label>
              <select v-model="form.client_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                <option disabled value="">Please select type</option>
                <option value="Supplier">Supplier</option>
                <option value="Liquidity Provider">Liquidity Provider</option>
              </select>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Address
              </label>
              <input
                type="text" v-model="form.address"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Contact Person
              </label>
              <input
                type="text" v-model="form.key_person"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
<!--          <div class="w-full lg:w-4/12 px-4">-->
<!--            <div class="relative w-full mb-3">-->
<!--              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">-->
<!--                Volume in Local Currency-->
<!--              </label>-->
<!--              <input-->
<!--                type="number" v-model="form.local_volume"-->
<!--                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit">Submit</button>
        </div>

      </form>
    </div>
  </div>

    </div>
  </div>
</template>
<script>

import core_function from "@/services/core_function";
export default {
  data(){
    return{
      form: {},
      clients: [],
      currencies: [],
    }
  },
  components: {
    // CardSettings,
    // CardProfile,
  },
  methods: {
    async postClient(){
      try{
        let response = await core_function.postClient(this.form)
        if(response.status === 201){
          this.$router.push("/admin/clients")
        }else{
          console.log(response)
        }
      }catch(err){
        console.log(err)
      }
    }
  },
  mounted() {

  }
};
</script>
