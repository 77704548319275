<template>
    <div class="features-section">
      <div class="features-content">
        <img src="/assets/images/global-payment-img.png" alt="Features Image" class="features-image" />
        <div class="features-list">
          <h2>Sign up to start saving on international money transfers and currency exchange.</h2>
          <div class="feature-cards">
            <div class="feature-card">
              <div class="icon">⬆️</div>
              <h3>Peace of Mind</h3>
              <p>Send and receive money without worries</p>
            </div>
            <div class="feature-card">
              <div class="icon">💼</div>
              <h3>Business-Ready</h3>
              <p>Boost your business with our api and grow with our cash transfer services.</p>
            </div>
            <div class="feature-card">
              <div class="icon">🔍</div>
              <h3>100% Transparent</h3>
              <p>Our rates and fee is transparent with no hidden cost.</p>
            </div>
            <div class="feature-card">
              <div class="icon">🌍</div>
              <h3>International Network</h3>
              <p>We are connected to over 50 countries to enables seamless transaction.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FeaturesSection',
  };
  </script>
  
  <style scoped>
  .features-section {
    display: flex;
    align-items: center; /* Center vertically */
    padding: 2rem;
    background-color: #f8f9fa; /* Light background color */
  }
  
  .features-content {
    display: flex;
    flex-direction: row; /* Align horizontally */
    width: 100%;
  }
  
  .features-image {
    flex: 1; /* Take 50% of the space */
    max-width: 100%; /* Ensure it doesn't overflow */
    border-radius: 10px; /* Rounded corners */
  }
  
  .features-list {
    flex: 1; /* Take 50% of the space */
    padding: 2rem;
  }
  
  h2 {
    margin-bottom: 1.5rem; /* Spacing below the header */
    font-size: 1.5rem; /* Font size */
    color: #333; /* Text color */
  }
  
  .feature-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns layout */
    gap: 1rem; /* Space between cards */
  }
  
  .feature-card {
    background: white; /* Card background */
    border-radius: 10px; /* Rounded corners */
    padding: 1.5rem; /* Card padding */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center text */
  }
  
  .icon {
    font-size: 2rem; /* Icon size */
    margin-bottom: 0.5rem; /* Spacing below icon */
  }
  
  h3 {
    font-size: 1.25rem; /* Feature title size */
    margin-bottom: 0.5rem; /* Spacing below title */
  }
  
  p {
    font-size: 0.9rem; /* Feature description size */
    color: #666; /* Text color */
  }
  </style>
  