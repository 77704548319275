<template>
  <div>
<!-- <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-3">hismql</div>
    <div class="col-sm-12 col-md-7">big</div>
  </div>
</div> -->

  
    <div class="pay-anyone-anywhere">
      <div class="content-wrapper">
        <div class="left-section">
          
           
           
              <h4 class="sub-title">Trusted by over 20,000 customers</h4>
          <h3 class="title">Access Deep Liquidity in Africa</h3>
          <p class="description">
            DurraFX offers reliable, real-time access to liquidity for International Money Transfer Operators (IMTOs), collection merchants, and FX brokers across Africa. Our solutions are built for speed, transparency, and efficiency, providing unparalleled access to local currencies and cross-border settlement faster than traditional banks.

          </p>
          <div class="buttons">
            <button class="cta-button primary">Get Started Today</button>
            <button class="cta-button secondary">Request a Demo</button>
          </div>
       

            

          
         
        </div>
  
        <div class="right-section">
          <div class="exchange-card">
            <div class="exchange-input">
              <label>You send</label>
              <input type="number" v-model="sendAmount" />
              <select v-model="sendCurrency">
                <option v-for="currency in currencies" :key="currency" :value="currency">
                  {{ currency }}
                </option>
              </select>
            </div>
            <div class="exchange-details">
              <!-- <div>
                <span>Our fee:</span>
                <span>{{ fee }} {{ sendCurrency }}</span>
              </div> -->
              <div>
                <span>We'll Convert:</span>
                <span>{{ convertedAmount }} {{ sendCurrency }}</span>
              </div>
              <div>
                <span>Rate:</span>
                <span>{{ exchangeRate }}</span>
              </div>
            </div>
            <div class="exchange-input">
              <label>Recipient gets</label>
              <input type="number" :value="recipientGets" disabled />
              <select v-model="receiveCurrency">
                <option v-for="currency in currencies" :key="currency" :value="currency">
                  {{ currency }}
                </option>
              </select>
            </div>
            <button class="cta-button primary get-started">View Rates</button>
          </div>
        </div>
      </div>
      <!-- Background illustrations -->
      <img src="https://example.com/background-vector1.png" class="background-illustration vector1" alt="Vector 1" />
      <img src="https://example.com/background-vector2.png" class="background-illustration vector2" alt="Vector 2" />
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        sendAmount: 1000,
        sendCurrency: 'USD',
        receiveCurrency: 'AUD',
        exchangeRate: 1.3947,
        fee: 10.04,
        currencies: ['USD', 'AUD', 'EUR', 'KES'],
      };
    },
    computed: {
      convertedAmount() {
        return (this.sendAmount - this.fee).toFixed(2);
      },
      recipientGets() {
        return (this.convertedAmount * this.exchangeRate).toFixed(2);
      },
    },
  };
  </script>
  
  <style scoped>
  .pay-anyone-anywhere {
    position: relative;
    background: #f8faff; /* Light blue background similar to the provided image */
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    overflow: hidden; /* To hide the images that go beyond container boundaries */
  }
  
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin-top: 30px;
  }
  
  .left-section {
    max-width: 70%;
    padding-right: 2rem;
    position: relative;
    z-index: 2; /* Keep content above the background images */
    /* background-image: url('../../assets/img/banner-clock.png'); */
  }
  
  .sub-title {
    color: #2d9cdb; /* Light blue color */
    font-weight: 500;
    font-size: small;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
    color: #2e3b4e; /* Dark blue color */
  }
  
  .description {
    color: #2e3b4e;
    margin-bottom: 2rem;
  }
  
  .buttons {
    display: flex;
    gap: 1rem;
  }
  
  .cta-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
  }
  
  .primary {
    background: #4c4cff;
    color: #fff;
  }
  
  .primary:hover {
    background: #3737e6;
  }
  
  .secondary {
    background: #ffffff;
    color: #4c4cff;
    border: 2px solid #4c4cff;
  }
  
  .secondary:hover {
    background: #f0f0f0;
  }
  
  .right-section {
    max-width: 30%;
    position: relative;
    z-index: 2; /* Keep content above the background images */
  }
  
  .exchange-card {
    background: #ffffff; /* White card background */
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
  }
  
  .exchange-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .exchange-input label {
    font-weight: bold;
    margin-right: 1rem;
    color: #2e3b4e;
  }
  
  .exchange-input input {
    width: 50%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .exchange-input select {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .exchange-details {
    margin-bottom: 1.5rem;
  }
  
  .exchange-details div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .exchange-details span {
    font-size: 1rem;
    color: #2e3b4e;
  }
  
  .get-started {
    background: #4c4cff;
    color: #fff;
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.1rem;
    border: none;
  }
  
  .get-started:hover {
    background: #3737e6;
    transform: scale(1.02);
  }
  
  /* Background illustrations styling */
  .background-illustration {
    position: absolute;
    opacity: 0.15; /* Makes the vector images fade into the background */
    z-index: 1; /* Place them behind the content */
  }
  
  .vector1 {
    top: -50px;
    left: -100px;
    width: 300px;
  }
  
  .vector2 {
    bottom: -50px;
    right: -100px;
    width: 300px;
  }
  </style>
  