<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >

          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Sign in with credentials</small>
            </div>
            <form @submit.prevent="login()">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  v-model="formData.username"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  v-model="formData.password"
                />
              </div>
<!--              <div>-->
<!--                <label class="inline-flex items-center cursor-pointer">-->
<!--                  <input-->
<!--                    id="customCheckLogin"-->
<!--                    type="checkbox"-->
<!--                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"-->
<!--                  />-->
<!--                  <span class="ml-2 text-sm font-semibold text-blueGray-600">-->
<!--                    Remember me-->
<!--                  </span>-->
<!--                </label>-->
<!--              </div>-->

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
<!--            <router-link to="/auth/register" class="text-blueGray-200">-->
<!--              <small>Create new account</small>-->
<!--            </router-link>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
    };
  },
  computed: {
        ...mapGetters('Auth',['authUser', 'authStatus', 'authError']),
        // ...mapGetters('User', ['userType']),
    },
  methods: {
    async login(){
        this.loading = true
        // const { username, password } = this
        this.$store.dispatch('Auth/login', this.formData)
        .then(() => {
            if(this.authStatus === 2) {
                this.$store.dispatch('User/getUser').then(() => {})
                if (this.authUser.last_login.length === 0) {
                    this.$router.push('/update-password/ft');
                    return false
                }

                if (this.authUser.user_type === 'admin') {
                    this.$router.push('/admin');
                } else {
                    this.$router.push('/admin');
                }
                console.log("go to clients")

            }else if (this.authStatus === 3) {
                this.loading = !this.loading
            } else {
                this.loading = !this.loading
            }
        })
    },
  },
};
</script>
